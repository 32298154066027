import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import flyerData from "./flyerData";
import downloadFile from "../../utils/downloadFile";
import styles from "./FlyerDownloads.module.css";

const FlyerDownloads = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            {/* Floating Button */}
            <motion.button 
                className={styles.floatingButton}
                onClick={() => setIsOpen(true)}
                initial={{ scale: 1 }}
                animate={{ scale: [1, 1.1, 1] }}
                transition={{ duration: 1.5, repeat: Infinity, repeatType: "reverse" }}
            >
                Summer Programs
            </motion.button>

            {/* Overlay */}
            <AnimatePresence>
                {isOpen && (
                    <motion.div 
                        className={styles.overlay}
                        initial={{ opacity: 0, y: -30 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -30 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className={styles.overlayContent}>
                            <div className={styles.overlayHeader}>
                                <span className={styles.overlayTitle}>Click to Download Flyer</span>
                                <button className={styles.closeButton} onClick={() => setIsOpen(false)}>Close</button>
                            </div>

                            <div className={styles.flyerGrid}>
                                {flyerData.map((flyer) => (
                                    <motion.div 
                                        key={flyer.id} 
                                        className={styles.flyerCard}
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        onClick={() => downloadFile(flyer.file, flyer.filename)}
                                    >
                                        <img src={flyer.image} alt={flyer.title} className={styles.flyerImage} />
                                        <span className={styles.flyerTitle}>{flyer.title}</span>
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default FlyerDownloads;