import minicamps from "../../images/flyers/minicamps.webp";
import summerintensive from "../../images/flyers/summerintensive.webp";
import summerDanceProgram from "../../images/flyers/summerDanceProgram.webp";
import SummerDanceProgramPDF from "../../assets/SummerDanceProgram.pdf";

const flyerData = [
    {
        id: 1,
        title: "Summer Intensive",
        image: summerintensive,
        file: summerintensive, 
        filename: "Summer-Intensive-2025.webp",
        type: "image"
    },
    {
        id: 2,
        title: "Mini Camps",
        image: minicamps,
        file: minicamps, 
        filename: "Mini-Camps-2025.webp",
        type: "image"
    },
    {
        id: 3,
        title: "Summer Dance Program",
        image: summerDanceProgram, 
        file: SummerDanceProgramPDF, 
        filename: "Summer-Dance-Program-2025.pdf",
        type: "pdf"
    }
];

export default flyerData;