import { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import Title from "../../components/Title/Title";
import Announcement from "../../components/Announcement/Announcement";
import ClassSchedulePreview from '../../components/ClassSchedule/ClassSchedulePreview';
import ClassesPreview from '../../components/Classes/ClassesPreview';
import GetInTouch from "../../components/GetInTouch/GetInTouch";
import FlyerDownloads from '../../components/FlyerDownloads/FlyerDownloads';
import styles from "./Home.module.css";

const Home = () => {
    const titleRef = useRef(null);
    const announcementRef = useRef(null);
    const getInTouchRef = useRef(null);
    const classSchedulePreviewRef = useRef(null);
    const classesPreviewRef = useRef(null);

    const titleInView = useInView(titleRef, { triggerOnce: true });
    const announcementInView = useInView(announcementRef, { triggerOnce: true });
    const getInTouchInView = useInView(getInTouchRef, { triggerOnce: true });
    const classSchedulePreviewInView = useInView(classSchedulePreviewRef, { triggerOnce: true });
    const classesPreviewInView = useInView(classesPreviewRef, { triggerOnce: true });

    return (
        <div className={styles.homeContainer}>
            <motion.div
                ref={titleRef}
                initial={{ opacity: 0 }}
                animate={{ opacity: titleInView ? 1 : 0 }}
                transition={{ duration: 0.8 }}
                className={styles.childContainer}
            >
                <Title />
            </motion.div>
            <motion.div
                ref={announcementRef}
                initial={{ opacity: 0 }}
                animate={{ opacity: announcementInView ? 1 : 0 }}
                transition={{ duration: 0.8 }}
                className={styles.childContainer}
            >
                <Announcement />
            </motion.div>
            <motion.div
                ref={classSchedulePreviewRef}
                initial={{ opacity: 0 }}
                animate={{ opacity: classSchedulePreviewInView ? 1 : 0 }}
                transition={{ duration: 0.8 }}
                className={styles.childContainer}
            >
                <ClassSchedulePreview />
            </motion.div>
            <motion.div
                ref={classesPreviewRef}
                initial={{ opacity: 0 }}
                animate={{ opacity: classesPreviewInView ? 1 : 0 }}
                transition={{ duration: 0.8 }}
                className={styles.childContainer}
            >
                <ClassesPreview />
            </motion.div>
            <motion.div
                ref={getInTouchRef}
                initial={{ opacity: 0 }}
                animate={{ opacity: getInTouchInView ? 1 : 0 }}
                transition={{ duration: 0.8 }}
                className={styles.childContainer}
            >
                <GetInTouch />
            </motion.div>
            {/* Floating FlyerDownloads Button (persists on screen above everything) */}
            <FlyerDownloads />
        </div>
    );
};

export default Home;
