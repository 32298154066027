const downloadFile = async (fileUrl, filename, fileType) => {
    try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const link = document.createElement("a");

        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Append and trigger download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
    } catch (error) {
        console.error("Error downloading the file:", error);
    }
};

export default downloadFile;